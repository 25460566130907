export function tmplVideo(film) {
  let str = `<div class="modal" id="modal2" tabindex="-1">
                  <div class="modal-dialog">
                    <div class="modal-content">
                                         
                    <div class="card ratio ratio-16x9">
                    <iframe src="${film.preview}" title="Player One" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                   
            </div><!-- end card -->
                     
                    </div>
                  </div>
              </div>
  `;
  return str;
}
