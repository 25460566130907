export function tmplLogin() {
 let str = `<div class="modal" id="modal1" tabindex="-1">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title">Connection</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                    <form class="row g-2">
                      <div class="col-md-6">
                        <label for="validationDefaultUsername" class="form-label">Courriel</label>
                        <div class="input-group">
                          <span class="input-group-text" id="inputGroupPrepend2">@</span>
                          <input type="text" class="form-control" id="validationDefaultUsername"  aria-describedby="inputGroupPrepend2" required>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <label for="validationDefault03" class="form-label">Password</label>
                        <input type="password" class="form-control" id="validationDefault03" required>
                      </div>
                  </form>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                      <button type="submit" class="btn btn-primary" data-bs-dismiss="modal">Envoyer</button>
                    </div>
                  </div>
                </div>
            </div>
`;
  return str;
}
