const strLen = 100;
export function tmplCard(film) {
  return ` <div class="col">
  <div id="card${film.id}" class="card mcard">
    <div class="embed-responsive embed-responsive-16by9">
        <img
        src="${film.posterUrl}"
        class="card-img-top embed-responsive-item"
        alt="${film.title}"
        />
    </div>
    <div class="card-body mcard-body">
      <h6 class="card-title">${film.title}(${film.year})</h6>
      <span class="card-text">
      ${categories(film.genres)}
      <h6>description</h6>
      <blockquote><small><em>${chortDescript(
        film.plot
      )}</em></small></blockquote>
      </span>
     
    </div>
    <row>
    <div class="card-footer d-grid gap-2 d-md-flex justify-content-md-end">
      ${link(film)}
      ${dtls(film.id)}
      </div>
    </row>
  </div>
  </div>`;
}
const link = (film) => {
  let link = "";
  if (film.preview) {
    link += `<button id="fi${film.id}" param="${film.id}" class="filmLink btn btn-primary me-md-2">Trailer</button>`;
  }
  return link;
};
const dtls = (id) => {
  return `<button id="${id}" class="filmInfo btn btn-primary me-md-2" type="button">Detailles</button>`;
};

const chortDescript = (desc) => {
  let str = "";
  if (!desc) {
    return str;
  }
  if (desc.length > strLen) {
    str = desc.substring(0, strLen);
    const i = str.lastIndexOf(" ");
    str = str.substring(0, i) + "...";
  } else {
    return desc;
  }
  return str;
};
const categories = (ctg) => {
  let str = "";
  if (!ctg) {
    return "";
  }
  for (let i = 0; i < ctg.length && i < 3; i++) {
    if (i === 0) {
      str += ctg[i];
    } else {
      str += ", " + ctg[i];
    }
  }
  return `<dd class="col-sm-12"><small><em>${str}</em></small></dd>`;
  
};
