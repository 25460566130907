/* jshint esversion: 8 */
//import section
import { getFilms } from "./services/films-service.js";
//import { getFilmsLocal } from "./services/films-service.js";
import { tmplCard } from "./templates/tmplCard.js";
import { tmplRegister } from "./templates/tmplRegister.js";
import { tmplFilmInfo } from "./templates/tmplFilmInfo.js";
import { tmplVideo } from "./templates/tmplVideo.js";
import { tmplLogin } from "./templates/tmplLogin.js";

import "./../css/styles.css";

var tabFilms = []; //data from JSON

let vueListeFilms = "";
let lstCategories = [];

//resevoire le list des films
async function setFilms() {
  await getFilms().then((response) => {
    tabFilms = response;
  });
  setCategories();
  lstFilms();
  setEventsStatic();
}
// function setFilms() {
//   tabFilms = getFilmsLocal();

//   setCategories();
//   lstFilms();
//   setEventsStatic();
// }

//chargement des films avec un filtre
function lstFilms(par) {
  vueListeFilms = "";
  let str = "Le list des films";
  let i = 0;
  document.getElementById("navbarSupportedContent").classList.remove("show");
  for (var unFilm of tabFilms) {
    if (par) {
      //const s = unFilm.genres[f].toUpperCase();
      if (unFilm.genres.includes(par)) {
        vueListeFilms += tmplCard(unFilm);
        // setCart(unFilm);
        i++;
      }
    } else {
      vueListeFilms += tmplCard(unFilm);
      // setCart(unFilm);
      i++;
    }
  }
  str += par ? " de categorie: " + par : "";
  str += "(" + i + ")";
  document.getElementById("filmsTitle").innerText = str;
  document.getElementById("lstCards").innerHTML = vueListeFilms;
  setEventsDinamic();
  window.scrollTo({ top: 0, behavior: "smooth" });
}

//obtenir les categories des films
const setCategories = () => {
  for (var item of tabFilms) {
    let genres = item.genres;
    for (let i = 0; i < genres.length; i++) {
      if (lstCategories.length > 0) {
        if (lstCategories.indexOf(genres[i]) < 0) {
          lstCategories.push(genres[i]);
        }
      } else {
        lstCategories.push(genres[i]);
      }
    }
  }
  lstCategories.sort();
  vueCategories();
};

//categories html template
const vueCategories = () => {
  let source = "";
  for (let i = 0; i < lstCategories.length; i++) {
    source += `<li><span id="ct${lstCategories[i]}" param="${lstCategories[i]}" class="spCat dropdown-item">${lstCategories[i]}</span></li>`;
  }
  document.getElementById("categories").innerHTML = source;
};

//chargement des films avec un filtre
const findFilms = () => {
  vueListeFilms = "";
  let str = "";
  let i = 0;
  let par = document.getElementById("findFilm").value;

  for (var unFilm of tabFilms) {
    if (par && unFilm.title.toUpperCase().includes(par.toUpperCase()) > 0) {
      vueListeFilms += tmplCard(unFilm);
      // setCart(unFilm);
      i++;
    }
  }
  str += i + " films ont été trouvés.";

  document.getElementById("filmsTitle").innerText = str;
  document.getElementById("lstCards").innerHTML = vueListeFilms;
  document.getElementById("navbarSupportedContent").classList.remove("show");
  setEventsDinamic();
  window.scrollTo({ top: 0, behavior: "smooth" });
};

//metode pour ajouter un event
const setEvents = (el, evnt, func, arg) => {
  let item = document.getElementById(el);
  item.removeEventListener(evnt, (e) => {}, false);
  item.addEventListener(
    evnt,
    () => {
      if (arg) {
        func(arg);
      } else {
        func();
      }
    },
    false
  );
};

//ajouter events pour les elemants static
const setEventsStatic = () => {
  setEvents("brand", "click", lstFilms);
  setEvents("home", "click", lstFilms);
  setEvents("frmFind", "click", findFilms);

  setEvents(
    "enregistrer",
    "click",
    showMdl,
    document.getElementById("enregistrer").id
  );
  setEvents(
    "connection",
    "click",
    showMdl,
    document.getElementById("connection").id
  );

  let categories = document.querySelectorAll(".spCat");
  for (let category of categories) {
    setEvents(category.id, "click", lstFilms, category.innerText);
  }
};
//ajouter events our les elemants dinamique
const setEventsDinamic = () => {
  let filmInfo = document.querySelectorAll(".filmInfo");
  for (let info of filmInfo) {
    setEvents(info.id, "click", showMdl, info.id);
  }

  let filmPreview = document.querySelectorAll(".filmLink");
  for (let info of filmPreview) {
    setEvents(info.id, "click", showModFilm, info.attributes.param.value);
  }
};

//forme modal general pour afficher les form: connexion,s'inscrire et info de film
var modalWrap = null;
const showMdl = (arg) => {
  if (modalWrap != null) {
    modalWrap.remove();
  }

  modalWrap = document.createElement("div");

  switch (arg.toLowerCase()) {
    case "enregistrer":
      modalWrap.innerHTML = tmplRegister();
      break;
    case "connection":
      modalWrap.innerHTML = tmplLogin();
      break;
    default:
      const film = tabFilms.find((f) => f.id === parseInt(arg));
      modalWrap.innerHTML = tmplFilmInfo(film);
      break;
  }

  document.body.append(modalWrap);
  var m = new bootstrap.Modal(modalWrap.querySelector("#modal1"));
  document.getElementById("navbarSupportedContent").classList.remove("show");
  m.show();
};
//forme modal pour afficher le video
var modalFilm = null;
const showModFilm = (id) => {
  if (modalFilm != null) {
    modalFilm.remove();
  }

  modalFilm = document.createElement("div");
  const flm = tabFilms.find((f) => f.id === parseInt(id));
  modalFilm.innerHTML = tmplVideo(flm);

  document.body.append(modalFilm);
  const m = modalFilm.querySelector("#modal2");
  m.addEventListener("hidden.bs.modal", function (event) {
    this.remove();
  });
  var fm = new bootstrap.Modal(m);
  document.getElementById("navbarSupportedContent").classList.remove("show");
  fm.show();
};

setFilms();




