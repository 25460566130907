/* jshint esversion: 8 */

import { local_data } from "../../donnees/films-local.js";

export const getFilms = async () => {
  //Fetch un fichier json

  let url = "/donnees/films.json";
  let reponse = await fetch(url);
  let data = await reponse.json();

  data.sort(SortByTitle);

  return data; // lit reponse du body et retourne en format JSON
};

export const getFilmsLocal = () => {
  let data = local_data;

  data.sort(SortByTitle);
  return data;
};
function SortByTitle(x, y) {
  return x.title == y.title ? 0 : x.title > y.title ? 1 : -1;
}
