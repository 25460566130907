export function tmplFilmInfo(film) {
  let str = `<div class="modal" id="modal1" tabindex="-1">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title">${film.title} (${film.year})-${
    film.runtime
  }min</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      
                    </div>
                    <div class="modal-body">
                        <div class="card mb-3">
                          <div class="row g-0">
                            <div class="col-md-4">
                              <img src="${
                                film.posterUrl
                              }" class="img-fluid rounded-start" alt="">
                            </div>
                            <div class="col-md-8">
                              <div class="card-body" style="overflow-y: auto;">
                              
                                <p class="card-text"><small ><em>${
                                  film.plot
                                }</em></small>
                                </p>
                                <p class="card-text">Genres: ${categories(
                                  film.genres
                                )}<br>
                                Réalisateur: <small><em>${
                                  film.director
                                }</em></small><br>
                                Acteurs:<small><em> ${
                                  film.actors
                                }</em></small</p>
                                
                              
                            </div>
                          </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                    </div>
                  </div>
                </div>
            </div>
`;
  return str;
}
const categories = (ctg) => {
  let str = "";
  if (!ctg) {
    return "";
  }
  for (let i = 0; i < ctg.length; i++) {
    if (i === 0) {
      str += ctg[i];
    } else {
      str += ", " + ctg[i];
    }
  }
  return `<small><em>${str}</em></small>`;
};
